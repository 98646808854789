body {
  margin: 0;
  font-family: 'IBM Plex Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000028;
  color: white;
  overflow-x: hidden;
}

*{
  box-sizing: border-box;
}

.app-content{
  width: 100%;
  overflow: hidden;
}

.app-content.fixed{
  position: fixed;
}